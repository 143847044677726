import Articles from '@/components/Articles'
import Layout from '@/components/Layout'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { Component } from 'react'
import readingTime from 'reading-time'

export default class PostPage extends Component<{}, {}> {
    // @ts-ignore
    data = this.props.pageContext as {
        title: string
        date: string
        image?: { file: {url: string} }
        content: any
    }

    estimatedTime = Math.round(readingTime(documentToPlainTextString(JSON.parse(this.data.content.raw))).minutes);

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Layout title={this.data.title} active={-1}>
                <div className="p-margin-x">
                    <div className="post">
                        <div className="post__heading">
                            <h1 className="post__title">{this.data.title}</h1>
                            <h3>{new Date(this.data.date).toISOString().split('T')[0]} &bull; {this.estimatedTime > 1 ? `${this.estimatedTime} minuters lästid.` : `Kort inlägg.`}</h3>
                        </div>
                        {this.data.image ? (<img className="post__main-img" src={this.data.image.file.url}/>) : "" }
                        {documentToReactComponents(JSON.parse(this.data.content.raw))}
                    </div>
                </div>

                <Articles amount={3} bottom={false}/>
            </Layout>
        )
    }
}
